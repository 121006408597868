const smoothScroll = ()=> {
  $('a[href^="#"]').on('click', e => {
    const speed = 600;
    const href = $(this).attr('href');
    const target = $(href == '#' || href == '' ? 'html' : href);
    const position = target.offset().top;
    $('body, html').delay(200).animate({scrollTop: position}, speed, 'swing');
    return false;
  });
};

const btnClickFunc = () => {
  const $btns = $('.js-btn');

  $btns.on('click', e => {
    const btnTargetData = $(e.currentTarget).attr('data-btn');
    const btnAnimationData = $(e.currentTarget).attr('data-animation');
    const target = $(`[data-target = "${btnTargetData}"]`);
    const btnTargetGroupData = $(e.currentTarget).attr('data-btnGroup');
    const $sameBtns = $(`[data-btnGroup = "${btnTargetGroupData}"]`);
    const $sameTargets = $(`[data-targetGroup = "${btnTargetGroupData}"]`);

    if (btnAnimationData == 'slide') {
      $(e.currentTarget).toggleClass('js-active');
      target.slideToggle(500);
    } else if (btnAnimationData == 'class') {
      $(e.currentTarget).toggleClass('js-active');
      target.toggleClass('js-active');
    } else if (btnAnimationData == 'fade') {
      $(e.currentTarget).toggleClass('js-active');
      target.fadeToggle(500);
    } else if (btnAnimationData == 'commonFade') {
      $sameBtns.toggleClass('js-active');
      target.fadeToggle(500);
    } else if (btnAnimationData == 'allClass') {
      $(e.currentTarget).toggleClass('js-active');
      $sameTargets.addClass('js-active');
    } else if (btnAnimationData == 'tab') {
      $sameBtns.not($(e.currentTarget)).removeClass('js-active');
      $(e.currentTarget).addClass('js-active');
      $sameTargets.removeClass('js-active');
      target.addClass('js-active');
    } else if (btnAnimationData == 'pageTop') {
      $('body, html').animate({
        scrollTop: 0,
      }, 700);
      return false;
    } else {
      console.log($(e.currentTarget));
      $(e.currentTarget).toggleClass('js-active');
    }
  });
};


function WOWanimate() {
  new WOW().init();
}

$(function() {
  smoothScroll();
  btnClickFunc();
  WOWanimate();
});
